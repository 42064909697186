import React, { createRef, useState, useEffect } from 'react';
import firebase from '../../firebase';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';
import { Facebook } from 'react-spinners-css';

import TotalAmount from './TotalAmount';
import Modal from '../Modal/index';
import { addBooking } from '../../reducers/bookings';
import { useDispatch } from 'react-redux';
import fr from "date-fns/locale/fr";
import { PaymentElement , useStripe, useElements } from '@stripe/react-stripe-js';


export const BookingForm = props => {
  const {
    bookingData,
    setBookingData,
    clearBookingData,
    setTotalPriceInBooking,
  } = props;
  const formRef = createRef();
  const modalRef = createRef();

  const dispatch = useDispatch();

  const [reservationMode, setReservationMode] = useState('multiple'); 
  const [errorMessages, setErrorMessages] = useState({});
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalMessage: '',
  });
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [reserveIsLoading, setReserveIsLoading] = useState(false);
  const [existingReservations, setExistingReservations] = useState([]);
  const [unavailableDates, setUnavailableDates] = useState([]); // Tableau de dates non disponibles
  const [unavailableDateDay, setUnavailableDateDay] = useState([]); // Tableau de dates non disponibles
  const [unavailableDateDayFinal, setUnavailableDateDayFinal] = useState([]); // Tableau de dates non disponibles

  useEffect(() => {
    // Récupérez une référence à la liste de réservations dans votre base de données
    const reservationsRef = firebase.database().ref('bookings');
  
    // Écoutez les modifications de la liste de réservations
    reservationsRef.on('value', (snapshot) => {
      const reservationsData = snapshot.val();
  
      if (reservationsData) {
        const reservationsArray = Object.values(reservationsData);
        setExistingReservations(reservationsArray);
  
        const newUnavailableDates = [];
        const newUnavailableDateDay = [];
  
        reservationsArray.forEach(reservation => {
          if (reservation.reservationMode === 'multiple') {
            const startDate = new Date(reservation.reserveDateStart);
            const endDate = new Date(reservation.reserveDateEnd);
  
            // Ajoutez le premier jour de la réservation à newUnavailableDates
            newUnavailableDates.push(startDate);
  
            const currentDate = new Date(startDate);
            currentDate.setDate(currentDate.getDate() + 1); // Incrémente d'un jour
  
            while (currentDate < endDate) {
              newUnavailableDates.push(new Date(currentDate));
              currentDate.setDate(currentDate.getDate() + 1);
            }
          } else if (reservation.reservationMode === 'single') {
            // Pour les réservations "single", ajoutez le jour suivant à newUnavailableDateDay
            const startDate = new Date(reservation.reserveDateStart);
            newUnavailableDateDay.push(startDate);
          }
        });
  
        setUnavailableDates(newUnavailableDates);
        setUnavailableDateDay(newUnavailableDateDay);
  
        console.log(unavailableDates);
        console.log(unavailableDateDay);
      }
    });
  
    // N'oubliez pas de nettoyer le gestionnaire d'écoute lorsque le composant est démonté
    return () => {
      reservationsRef.off();
    };
  }, []);
  


  const handleSingle = () => {
    setReservationMode('single');
    setBookingData({
      ...bookingData,
      reserveDateStart: null,
      reserveDateEnd: null,
    });
    setErrorMessages({ dates: '' });

    // Mettez à jour le tableau des dates non disponibles pour l'après-midi ici
    const newUnavailableDates = [];
    
    // Parcourez les réservations existantes et ajoutez les dates non disponibles
    existingReservations.forEach(reservation => {
      // Vérifiez si la réservation est "single" et ajoutez la date de réservation à newUnavailableDates
      if (reservation.reservationMode === 'single') {
        const startDate = new Date(reservation.reserveDateStart);
        newUnavailableDates.push(startDate);
      }
      
      // Vérifiez si la réservation est "multiple" et ajoutez le premier et le dernier jour de la réservation
      if (reservation.reservationMode === 'multiple') {
        const startDate = new Date(reservation.reserveDateStart);
        const endDate = new Date(reservation.reserveDateEnd);

        const dateRange = [];
        const currentDate = new Date(startDate);
        currentDate.setDate(currentDate.getDate() + 1); // Incrémente d'un jour
      
        while (currentDate < endDate) {
          dateRange.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }
      
        // Ajoutez toutes les dates de dateRange dans newUnavailableDates
        newUnavailableDates.push(...dateRange);
      }
    });

    // Mettez à jour l'état des dates non disponibles
    setUnavailableDates(newUnavailableDates);
    console.log(unavailableDates)
  };

  const handleMultiple = () => {
    setReservationMode('multiple');
    setBookingData({
      ...bookingData,
      reserveDateStart: null,
      reserveDateEnd: null,
    });

    // Mettez à jour le tableau des dates non disponibles pour les réservations multiples ici
    const newUnavailableDates = [];

    // Parcourez les réservations existantes et ajoutez les dates non disponibles
    existingReservations.forEach(reservation => {
      if (reservation.reservationMode === 'multiple') {
        const startDate = new Date(reservation.reserveDateStart);

        // Ajoutez le premier jour de la réservation à newUnavailableDates
        newUnavailableDates.push(startDate);
      }
    });

    // Mettez à jour l'état des dates non disponibles
    setUnavailableDates(newUnavailableDates);
    console.log(unavailableDates);
  };


  const setNameTelAndGuestCount = (e) => {
    const { name, value } = e.target;
    setBookingData({
      ...bookingData,
      [name]: value,
      reservationMode: reservationMode, 
    });
  };

  const setStartDate = (startDate = bookingData.reserveDateStart) => {
    let endDate = '';
  
    if (startDate >= bookingData.reserveDateEnd) {
      endDate = addDays(startDate, 1);
    }
  
    setErrorMessages({ dates: '' });
    setBookingData({
      ...bookingData,
      reserveDateStart: +new Date(startDate),
      reserveDateEnd: +new Date(endDate),
    });
  };
   

  const setEndDate = (endDate = bookingData.reserveDateEnd) => {
    const dateRange = getDatesBetween(bookingData.reserveDateStart, endDate);

    // Vérifiez si la plage de dates est valide
    const isDateRangeValid = !unavailableDates.some(date => {
      return date >= bookingData.reserveDateStart && date <= endDate;
    });
  
    if (isDateRangeValid) {
      
      if (dateRange.length >= 3 ) {
        
        if (unavailableDateDay.some(unavailableDate => {
          return dateRange.slice(1, -1).some(date => +date === +unavailableDate);
        })) {
          setBookingData({
            ...bookingData,
            reserveDateEnd: null,
          });
          setErrorMessages({ dates: 'Il y a une réservation d\'après-midi présente sur le séjour sélectionné, veuillez essayer d\'autres dates' });
        } else {
          setBookingData({
            ...bookingData,
            reserveDateEnd: +new Date(endDate),
          });
          setErrorMessages({ dates: '' });
        }
        
        
      } else {
        if(reservationMode === 'single'){
          setBookingData({
            ...bookingData,
            reserveDateEnd: null,
          });
        }else{
        setBookingData({
          ...bookingData,
          reserveDateEnd: +new Date(endDate),
        });
      }
        setErrorMessages({ dates: '' });
      }
    } else {
      // Affichez un message d'erreur si la plage de dates contient des dates non disponibles.
      setErrorMessages({ dates: 'La plage de dates contient des dates non disponibles.' });
    }
  };

  function getDatesBetween(startDate, endDate) {
    const dates = [];
    const currentDate = new Date(startDate);
  
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate)); // Créez une nouvelle instance de date
      currentDate.setDate(currentDate.getDate() + 1);
    }
 
    return dates;
  }
  

  const updateStyleToBody = () => {
    document.body.classList.toggle('modal-is-open');
  };

  const openModal = () => {
    const modalMessage =
      'Merci d\'avoir interagi avec Cheval à Bascule, un membre du personnel vous appellera pour confirmer votre réservation!';
    setModalState({ ...modalState, modalMessage, isModalOpen: true });
    updateStyleToBody();
  };

  const closeModal = () => {
    setModalState({ ...modalState, isModalOpen: false });
    updateStyleToBody();
  };

  const handleClickOutside = e => {
    const modal = modalRef.current;

    if (modal && modal.contains(e.target)) {
      return;
    }

    closeModal();
  };

  const validateForm = () => {
    const { guestName, tel, reserveDateStart, reserveDateEnd } = bookingData;
    const newErrorMessages = { ...errorMessages };
    const regPattern = /^\d+$/;

    newErrorMessages.guestName = guestName ? '' : 'Le nom est requis';
    newErrorMessages.guestCount = guestCount ? '' : `Le nombre d'invités est requis`;
    

    newErrorMessages.tel =
      tel && regPattern.test(tel) ? '' : 'Le numéro de téléphone est requis';

    newErrorMessages.dates =
      reserveDateStart && reserveDateEnd
        ? ''
        : 'Veuillez choisir vos dates d\'arrivée et de départ';

    setErrorMessages(newErrorMessages);

    if (Object.values(newErrorMessages).some(message => message !== '')) {
      return false;
    }

    return true;
  };

  const sendFormData = async () => {
    try {
      setReserveIsLoading(true);
      
      // Envoyer les données du formulaire au backend
      const response = await dispatch(addBooking({ ...bookingData, timestamp: +new Date() }));
  
      // Vérifier si la requête a réussi
      if (response.status === 200) {
        // Réinitialiser le formulaire
        clearBookingData();
        setBookingSuccess(true);
        setReserveIsLoading(false);
        openModal();
      } else {
        // Afficher un message d'erreur en cas de problème
        setErrorMessages({ submissionError: 'Erreur lors de la soumission du formulaire.' });
        setReserveIsLoading(false);
      }
    } catch (error) {
      // Gérer les erreurs générales (par exemple, problème de réseau)
      console.error('Erreur lors de la soumission du formulaire :', error);
      setErrorMessages({ submissionError: 'Une erreur s\'est produite lors de la soumission du formulaire.' });
      setReserveIsLoading(false);
      openModal(); // Vous pouvez afficher un message d'erreur modal ici si nécessaire
    }
    
  };

  const renderDatePickers = () => {
    if (reservationMode === 'single') {
      // Afficher un seul date picker pour une seule nuitée
      return (
        <div className="form__dates-wrapper">
        <DatePicker
          selected={reserveDateStart}
          onChange={setStartDate}
          locale={fr}
          minDate={addDays(new Date(), 1)}
          maxDate={addDays(new Date(), 90)}
          dateFormat="dd-MM-yyyy"
          placeholderText="Date d'arrivée"
          excludeDates={unavailableDates}
        />
        </div>
      );
    } else {
      return (
        <div className="form__dates-wrapper">
          <DatePicker
            selected={reserveDateStart}
            selectsStart
            startDate={reserveDateStart}
            endDate={reserveDateEnd}
            onChange={setStartDate}
            locale={fr}
            minDate={addDays(new Date(), 0)}
            maxDate={addDays(new Date(), 90)}
            dateFormat="dd-MM-yyyy"
            placeholderText="Arrivée"
            excludeDates={unavailableDates}
          />
          &#8594;
          <DatePicker
            selected={reserveDateEnd}
            selectsEnd
            startDate={reserveDateStart}
            endDate={reserveDateEnd}
            onChange={setEndDate}
            locale={fr}
            minDate={addDays(new Date(), 1)}
            maxDate={addDays(new Date(), 90)}
            dateFormat="dd-MM-yyyy"
            placeholderText="Départ"
            popperModifiers={{
              preventOverflow: {
                enabled: true,
                escapeWithReference: false,
                boundariesElement: 'viewport',
              },
            }}
            excludeDates={unavailableDates}
          />
        </div>
      );
    }
  };

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();

    const allValidated = validateForm();

    if (allValidated) {
      sendFormData();
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/completion`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsProcessing(false);
  };

  // const totalAmountComponent = (
  //   <TotalAmount
  //     {...{
  //       normalDayPrice,
  //       holidayPrice,
  //       setTotalPriceInBooking,
  //     }}
  //     startDate={reserveDateStart}
  //     endDate={reserveDateEnd}
  //     reservationMode={reservationMode}
  //   />
  // );

  const [extraRoom, setExtraRoom] = useState(false);

  const handleCheckboxChange = () => {
    setExtraRoom(!extraRoom);
  };

  
  const {
    guestName,
    tel,
    reserveDateStart,
    reserveDateEnd,
    guestCount,
  } = bookingData;
  const { normalDayPrice, holidayPrice, normalDayPriceAfternoon, holidayPriceAfternoon  } = props;

  return (
    <>
      <div className="booking-card__form">
        <form className="form" ref={formRef} onSubmit={submitForm}>
          <div className="form__field">
            <p htmlFor="guestName" className="form__label">
              Nom de l'invité
            </p>
            <input
              type="text"
              className="form__input"
              name="guestName"
              value={guestName}
              onChange={setNameTelAndGuestCount}
            />
            <em className="form__error-text">{errorMessages.guestName}</em>
          </div>
          <div className="form__field">
            <p htmlFor="tel" className="form__label">
              Numéro de téléphone
            </p>
            <input
              type="text"
              className="form__input"
              name="tel"
              value={tel}
              onChange={setNameTelAndGuestCount}
            />
            <em className="form__error-text">{errorMessages.tel}</em>
          </div>
          <div className="form__field">
            <p htmlFor="tel" className="form__label">
              Nombre d'invités
            </p>
            <input
              type="number"
              className="form__input"
              name="guestCount"
              value={guestCount}
              onChange={setNameTelAndGuestCount}
            />
            <em className="form__error-text">{errorMessages.guestCount}</em>
          </div>
          <input type="checkbox" name="myCheckbox" />
          <div style={{display: "flex", width: "100%", marginBottom: 25}}>

          <div
              style={{
                width: 15,
                height: 15,
                border: '1px solid #000',
                borderRadius: '50px',
                marginRight: '8px',
                backgroundColor: extraRoom ? 'black' : 'transparent',
                cursor: "pointer",
                marginRight: 20
              }}
              onClick={handleCheckboxChange}
            />
          
            <p htmlFor="extra" className="form__label">
              Chambre supplémentaire (+ 50.00 €)
            </p>
            
          </div>

          <div className="form__field">
            <p className="form__label">Réservation</p>
            <div className="form__date-picker-mode">
            <button
                type="button"
                onClick={handleMultiple}
                className={`form__date-picker-mode-button ${
                  reservationMode === 'multiple' || reservationMode === '' ? 'active ' : ''
                }`}
              >
                Nuitée(s)
              </button>

              <button
                type="button"
                onClick={handleSingle}
                className={`form__date-picker-mode-button ${
                  reservationMode === 'single' ? 'active' : ''
                }`}
              >
                Après-midi
              </button>
              
            </div>
          </div>
          <div className="form__field">
            <p className="form__label">Date</p>
            {renderDatePickers()}
            <em className="form__error-text">{errorMessages.dates}</em>
          </div>
          {bookingData && (
            <>
            <TotalAmount
              {...{
                normalDayPrice,
                holidayPrice,
                normalDayPriceAfternoon,
                holidayPriceAfternoon,
                setTotalPriceInBooking,
              }}
              startDate={reserveDateStart}
              endDate={reserveDateEnd}
              reservationMode={reservationMode}
              extraRoom={extraRoom}
            />
            <PaymentElement/>
            <button disabled={isProcessing || !stripe || !elements} id="submit">
              <span id="button-text">
                {isProcessing ? "Processing ... " : "Pay now"}
              </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
            </>
          )}
          
    
          <div className="form__btn-wrapper">
            <button type="submit" className="form__submit-btn">
              {reserveIsLoading ? <Facebook color="#fff" /> : 'Réserver'}
            </button>
          </div>
        </form>
      </div>
      {modalState.isModalOpen && (
        <Modal
          {...{
            modalRef,
            closeModal,
            handleClickOutside,
            bookingSuccess,
          }}
          modalIsOpen={modalState.isModalOpen}
          modalMessage={modalState.modalMessage}
        />
      )}
    </>
  );
};

export default BookingForm;
