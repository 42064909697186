import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import firebase from '../../../firebase';
import './Calendar.scss';
import Title from './Title';
import ReservationInfo from './ReservationInfo';

function MyCalendar() {
  const [selectedDates, setSelectedDates] = useState([]);
  const [showReservationInfo, setShowReservationInfo] = useState(false);
  const [selectedReservations, setSelectedReservations] = useState([]);
  const [reservations, setReservations] = useState([]); // Ajout de l'état reservations

  useEffect(() => {
    const bookingsRef = firebase.database().ref('bookings');
    const reservations = [];

    bookingsRef.once('value', (snapshot) => {
      const allBookings = snapshot.val();

      if (allBookings) {
        const occupiedDates = [];

        Object.values(allBookings).forEach(booking => {
          if (booking.status !== 'paid' && booking.status !== 'reservationCancel') {
            if (booking.reservationMode === 'single') {
              occupiedDates.push({ date: new Date(booking.reserveDateStart), reservationMode: booking.reservationMode });
            } else if (booking.reservationMode === 'multiple') {
              let isFirstDate = true;

              for (let date = new Date(booking.reserveDateStart); date <= new Date(booking.reserveDateEnd); date.setDate(date.getDate() + 1)) {
                const currentDate = new Date(date.toDateString());

                if (currentDate.getTime() === new Date(booking.reserveDateStart).getTime()) {
                  occupiedDates.push({ date: currentDate, reservationMode: 'start' });
                }

                if (currentDate.getTime() === new Date(booking.reserveDateEnd).getTime()) {
                  occupiedDates.push({ date: currentDate, reservationMode: 'end' });
                  continue;
                }
            
                if (isFirstDate) {
                  isFirstDate = false; // Définir isFirstDate sur false après la première date
                  continue; // Sauter la première date
                }
            
                occupiedDates.push({ date: currentDate, reservationMode: booking.reservationMode });
                

              }
            }

            // Ajoutez la réservation au tableau de réservations
            reservations.push(booking);
          }
        });

        setSelectedDates(occupiedDates);
        setReservations(reservations); // Mettez à jour l'état reservations avec les données
      } else {
        console.log('Aucune donnée de réservation trouvée.');
      }
    }).catch((error) => {
      console.error('Une erreur s\'est produite :', error);
    });

    // Mettez les réservations à jour dans l'état ici, par exemple :
    // setReservationData(reservations);
  }, []);

  const tileContent = ({ date, view }) => {
    const matchingDates = selectedDates.filter(d => d.date.toDateString() === date.toDateString());
    if (view === 'month' && matchingDates.length > 0) {
      return (
        <div className="tile-content-wrapper">
          {matchingDates.map(dateInfo => (
            <div
              key={dateInfo.date}
              className={`occupied-day ${dateInfo.reservationMode}`}

            >
            </div>
          ))}
        </div>
      );
    }
  };

  const handleDayClick = (date) => {
    const reservationsOnSelectedDate = reservations.filter((reservation) => {
      return date >= new Date(reservation.reserveDateStart) && date <= new Date(reservation.reserveDateEnd);
    });

    if (reservationsOnSelectedDate.length > 0) {
      setSelectedReservations(reservationsOnSelectedDate);
      setShowReservationInfo(true);
    } else {
      setSelectedReservations([]);
      setShowReservationInfo(false);
    }
  };

  return (
    <>
      <Title>Calendrier des réservations</Title>
      <div className="calendar-container">
        {showReservationInfo ? (
          <ReservationInfo reservations={selectedReservations} onBackClick={() => setShowReservationInfo(false)} />
        ) : (
          <Calendar
            selectRange={false}
            value={new Date()}
            tileContent={tileContent}
            onClickDay={handleDayClick}
            className="custom-calendar"
          />
        )}
      </div>
    </>
  );
}

export default MyCalendar;
