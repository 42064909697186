import React, { useMemo, useState, useEffect } from 'react';

const TotalAmount = props => {
  const {
    extraRoom,
    reservationMode,
    normalDayPrice,
    holidayPrice,
    normalDayPriceAfternoon,
    holidayPriceAfternoon,
    startDate,
    endDate,
    setTotalPriceInBooking,
  } = props;
    // Utilisez l'état local pour suivre les nuits en semaine et de week-end
    const [totalNormalNights, setTotalNormalNights] = useState(0);
    const [totalHolidayNights, setTotalHolidayNights] = useState(0);
    const [typeOfDay, setTypeOfDay] = useState(0);
  
    const nightsBetween = (startDate, endDate) => {
      const start = new Date(startDate);
      const end = new Date(endDate);
    
      end.setDate(end.getDate() - 1);
    
      let normalNights = 0;
      let holidayNights = 0;
    
      while (start <= end) {
        const dayOfWeek = start.getDay();
        const isWeekend = dayOfWeek === 0 || dayOfWeek === 5 || dayOfWeek === 6;
    
        if (isWeekend) {
          holidayNights++;
        } else {
          normalNights++;
        }
    
        // Avancez d'un jour
        start.setDate(start.getDate() + 1);
      }
      // Mettez à jour les compteurs après la boucle
      setTotalNormalNights(normalNights);
      setTotalHolidayNights(holidayNights);
    };
    
    useEffect(() => {
      if (startDate && endDate) {
        nightsBetween(startDate, endDate);
      }

      const start = new Date(startDate);
      const firstDayOfWeek = start.getDay();
      if (firstDayOfWeek === 0 || firstDayOfWeek === 5 || firstDayOfWeek === 6) {
        setTypeOfDay('weekend')
      } else {
        setTypeOfDay("normalday")
      }
    }, [startDate, endDate]);
    
  
    const totalPrice = useMemo(() => {
      if (!startDate && !endDate) return null;
  
      // Calcul du prix total
      if(reservationMode == "single"){
        let price = totalNormalNights * normalDayPriceAfternoon + totalHolidayNights * holidayPriceAfternoon;
        if (extraRoom) {
          price += 50;
        }
    
        return price;
      }else{
        let price = totalNormalNights * normalDayPrice + totalHolidayNights * holidayPrice;
        if (extraRoom) {
          price += 50;
        }
    
        return price;
      }
      // console.log(price)
    }, [startDate, endDate, normalDayPrice, holidayPrice, totalNormalNights, totalHolidayNights, extraRoom]);
  

    const VATPrice = useMemo(() => {
      // définit le prix total pour le parent
      setTotalPriceInBooking(totalPrice + (totalPrice / 100) * 20);
  
      return (totalPrice / 100) * 20;
    }, [totalPrice]);
  

  if (!startDate && !endDate) return null;

  return (
    <div className="booking-card__total">
      {reservationMode === 'multiple' ? (
      <ul className="booking-card__total-list">
        <li className="booking-card__total-item">
          <span className="booking-card__total-text">
            Jours en semaine <br />{parseFloat(normalDayPrice).toFixed(2).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} € x{' '}
            {totalNormalNights} nuits
          </span>
          <span className="booking-card__total-text">
            {parseFloat((normalDayPrice * totalNormalNights).toFixed(2)).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €
          </span>
        </li>
        <li className="booking-card__total-item">
          <span className="booking-card__total-text">
            Jours en week-end <br /> {parseFloat(holidayPrice).toFixed(2).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} € x{' '}
            {totalHolidayNights} nuits
          </span>
          <span className="booking-card__total-text">
            {parseFloat((holidayPrice * totalHolidayNights).toFixed(2)).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €
          </span>
        </li>
        {extraRoom && (
          <li className="booking-card__total-item">
            <span className="booking-card__total-text">
             + Chambre supplémentaire
            </span>
            <span className="booking-card__total-text">
               50,00 €
            </span>
          </li>
        )}
        <li className="booking-card__total-item">
          <span className="booking-card__total-text">
            Taxe TVA <br />{parseFloat((totalPrice).toFixed(2)).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} € x 20%
          </span>
          <span className="booking-card__total-text">
          {parseFloat((VATPrice).toFixed(2)).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €
          </span>
        </li>
        <li className="booking-card__total-item">
          <span className="booking-card__total-text bold">Total</span>
          <span className="booking-card__total-text bold large">
          {parseFloat((totalPrice + VATPrice).toFixed(2)).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €
          </span>
        </li>
      </ul>
      ) : (
        typeOfDay === 'weekend' ? (
          <ul className="booking-card__total-list">
          <li className="booking-card__total-item">
            <span className="booking-card__total-text">
              Jour de week-end <br /> {parseFloat(holidayPriceAfternoon).toFixed(2).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} € x{' '}
              1 après-midi
            </span>
            <span className="booking-card__total-text">
              {parseFloat((holidayPriceAfternoon * 1).toFixed(2)).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €
            </span>
          </li>
          {extraRoom && (
          <li className="booking-card__total-item">
            <span className="booking-card__total-text">
             + Chambre supplémentaire
            </span>
            <span className="booking-card__total-text">
               50,00 €
            </span>
          </li>
           )}
          <li className="booking-card__total-item">
            <span className="booking-card__total-text">
              Taxe TVA <br />{parseFloat((totalPrice).toFixed(2)).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} € x 20%
            </span>
            <span className="booking-card__total-text">
            {parseFloat((VATPrice).toFixed(2)).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €
            </span>
          </li>
          <li className="booking-card__total-item">
            <span className="booking-card__total-text bold">Total</span>
            <span className="booking-card__total-text bold large">
            {parseFloat((totalPrice + VATPrice).toFixed(2)).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €
            </span>
          </li>
        </ul>
        ) : (
          <ul className="booking-card__total-list">
          <li className="booking-card__total-item">
            <span className="booking-card__total-text">
              Jour en semaine <br />{parseFloat(normalDayPriceAfternoon).toFixed(2).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} € x{' '}
              1 après-midi
            </span>
            <span className="booking-card__total-text">
              {parseFloat((normalDayPriceAfternoon * 1).toFixed(2)).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €
            </span>
          </li>
          {extraRoom && (
          <li className="booking-card__total-item">
            <span className="booking-card__total-text">
             + Chambre supplémentaire
            </span>
            <span className="booking-card__total-text">
               50,00 €
            </span>
          </li>
           )}
          <li className="booking-card__total-item">
            <span className="booking-card__total-text">
              Taxe TVA <br />{parseFloat((totalPrice).toFixed(2)).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} € x 20%
            </span>
            <span className="booking-card__total-text">
            {parseFloat((VATPrice).toFixed(2)).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €
            </span>
          </li>
          <li className="booking-card__total-item">
            <span className="booking-card__total-text bold">Total</span>
            <span className="booking-card__total-text bold large">
            {parseFloat((totalPrice + VATPrice).toFixed(2)).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €
            </span>
          </li>
        </ul>
        )
      )}
    </div>
  );
};

export default TotalAmount;
