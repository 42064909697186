import React from 'react';
import './ReservationInfos.scss'; 
import {statusString,} from '../../../utils';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale'; // Importez le locale français


function ReservationInfo({ reservations, onBackClick }) {
  return (
    <div className="reservation-info">
      <div className="header">
        <button onClick={onBackClick} className="back-button">
          <img src='/images/arrow-left.svg' alt="Retour" />
        </button>
        <h2>Informations sur les réservations</h2>
      </div>
      <ul>
        {reservations.map((reservation, index) => (
          <li key={index} className="reservation-item">
            <div className="reservation-details">
              <h3>Réservation {index + 1}</h3>
              <p><strong>Date de début :</strong> {format(new Date(reservation.reserveDateStart), 'dd MMMM yyyy', { locale: fr })}</p>
              <p><strong>Date de fin :</strong> {format(new Date(reservation.reserveDateEnd), 'dd MMMM yyyy', { locale: fr })}</p>
              <p><strong>Créneau :</strong> {reservation.reservationMode === 'multiple' ? '18H30 à 11H00' : '12H30 à 16H30'}</p>
              <p><strong>Nom de l'invité :</strong> {reservation.guestName}</p>
              <p><strong>Nombre d'invités :</strong> {reservation.guestCount}</p>
              <p><strong>Numéro de téléphone :</strong> {reservation.tel}</p>
              <p><strong>Prix total :</strong> {reservation.totalPrice} €</p>
              <p><strong>Statut :</strong> {statusString[reservation.status]}</p>
              {/* Ajoutez d'autres informations de réservation si nécessaire */}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ReservationInfo;
