import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import React from 'react';

const ManageRoomAmenities = props => {
  const { amenities, setAmenities } = props;

  const {
    wifi,
    breakfast,
    minibar,
    roomService,
    airConditioner,
    greatView,
    smokeFree,
    childFriendly,
    petFriendly,
    spa,
    sauna,
    tv,
  } = amenities;

  const handleChange = e => {
    const { name } = e.target;
    setAmenities({ name, value: !amenities[name] });
  };

  return (
    <>
      <span className="room-info__check-title">Équipements</span>
      <FormControl component="fieldset">
        <FormGroup
          className="room-amenities__list"
          style={{ borderTop: 'none', userSelect: 'none' }}
        >
          <FormControlLabel
            className={`room-amenities__item ${wifi ? '' : 'non-disponible'}`}
            control={
              <Checkbox
                color="primary"
                checked={wifi}
                onChange={handleChange}
                name="wifi"
              />
            }
            label={
              <>
                <img
                  src="/images/room-wifi.svg"
                  alt="Wi-Fi"
                  className="room-amenities__icon"
                />
                <span className="room-amenities__title">Wi-Fi</span>
              </>
            }
          />
          <FormControlLabel
            className={`room-amenities__item ${spa ? '' : 'non-disponible'}`}
            control={
              <Checkbox
                color="primary"
                checked={spa}
                onChange={handleChange}
                name="spa"
              />
            }
            label={
              <>
                <img
                  src="/images/hot-tub-person.svg"
                  alt="Spa"
                  className="room-amenities__icon"
                />
                <span className="room-amenities__title">Spa</span>
              </>
            }
          />
          <FormControlLabel
            className={`room-amenities__item ${sauna ? '' : 'non-disponible'}`}
            control={
              <Checkbox
                color="primary"
                checked={sauna}
                onChange={handleChange}
                name="sauna"
              />
            }
            label={
              <>
                <img
                  src="/images/person-shelter.svg"
                  alt="Spa"
                  className="room-amenities__icon"
                />
                <span className="room-amenities__title">Sauna</span>
              </>
            }
          />
          <FormControlLabel
            className={`room-amenities__item ${tv ? '' : 'non-disponible'}`}
            control={
              <Checkbox
                color="primary"
                checked={tv}
                onChange={handleChange}
                name="tv"
              />
            }
            label={
              <>
                <img
                  src="/images/tv.svg"
                  alt="Spa"
                  className="room-amenities__icon"
                />
                <span className="room-amenities__title">Vidéo projecteur</span>
              </>
            }
          />
          <FormControlLabel
            className={`room-amenities__item ${breakfast ? '' : 'non-disponible'}`}
            control={
              <Checkbox
                color="primary"
                checked={breakfast}
                onChange={handleChange}
                name="breakfast"
              />
            }
            label={
              <>
                <img
                  src="/images/room-breakfast.svg"
                  alt="Petit Déjeuner"
                  className="room-amenities__icon"
                />
                <span className="room-amenities__title">Petit Déjeuner</span>
              </>
            }
          />
          <FormControlLabel
            className={`room-amenities__item ${minibar ? '' : 'non-disponible'}`}
            control={
              <Checkbox
                color="primary"
                checked={minibar}
                onChange={handleChange}
                name="minibar"
              />
            }
            label={
              <>
                <img
                  src="/images/room-bar.svg"
                  alt="Minibar"
                  className="room-amenities__icon"
                />
                <span className="room-amenities__title">Minibar</span>
              </>
            }
          />
          <FormControlLabel
            className={`room-amenities__item ${roomService ? '' : 'non-disponible'}`}
            control={
              <Checkbox
                color="primary"
                checked={roomService}
                onChange={handleChange}
                name="roomService"
              />
            }
            label={
              <>
                <img
                  src="/images/room_service.svg"
                  alt="Service en Chambre"
                  className="room-amenities__icon"
                />
                <span className="room-amenities__title">Service en Chambre</span>
              </>
            }
          />
          <FormControlLabel
            className={`room-amenities__item ${airConditioner ? '' : 'non-disponible'}`}
            control={
              <Checkbox
                color="primary"
                checked={airConditioner}
                onChange={handleChange}
                name="airConditioner"
              />
            }
            label={
              <>
                <img
                  src="/images/room-breeze.svg"
                  alt="Climatisation"
                  className="room-amenities__icon"
                />
                <span className="room-amenities__title">Climatisation</span>
              </>
            }
          />
          <FormControlLabel
            className={`room-amenities__item ${greatView ? '' : 'non-disponible'}`}
            control={
              <Checkbox
                color="primary"
                checked={greatView}
                onChange={handleChange}
                name="greatView"
              />
            }
            label={
              <>
                <img
                  src="/images/room-mountain-range.svg"
                  alt="Belle Vue"
                  className="room-amenities__icon"
                />
                <span className="room-amenities__title">Belle Vue</span>
              </>
            }
          />
          <FormControlLabel
            className={`room-amenities__item ${smokeFree ? '' : 'non-disponible'}`}
            control={
              <Checkbox
                color="primary"
                checked={smokeFree}
                onChange={handleChange}
                name="smokeFree"
              />
            }
            label={
              <>
                <img
                  src="/images/room-no-smoke-symbol.svg"
                  alt="Non-Fumeur"
                  className="room-amenities__icon"
                />
                <span className="room-amenities__title">Non-Fumeur</span>
              </>
            }
          />
          <FormControlLabel
            className={`room-amenities__item ${petFriendly ? '' : 'non-disponible'}`}
            control={
              <Checkbox
                color="primary"
                checked={petFriendly}
                onChange={handleChange}
                name="petFriendly"
              />
            }
            label={
              <>
                <img
                  src="/images/room-dog.svg"
                  alt="Animaux Bienvenus"
                  className="room-amenities__icon"
                />
                <span className="room-amenities__title">Animaux Bienvenus</span>
              </>
            }
          />
          <FormControlLabel
            className={`room-amenities__item ${childFriendly ? '' : 'non-disponible'}`}
            control={
              <Checkbox
                color="primary"
                checked={childFriendly}
                onChange={handleChange}
                name="childFriendly"
              />
            }
            label={
              <>
                <img
                  src="/images/room-crawling-baby-silhouette.svg"
                  alt="Enfant Bienvenu"
                  className="room-amenities__icon"
                />
                <span className="room-amenities__title">Enfant Bienvenu</span>
              </>
            }
          />
        </FormGroup>
      </FormControl>
    </>
  );
};

export default ManageRoomAmenities;
