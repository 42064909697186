import React, { Component } from 'react';

const slideImages = [
  'https://images.unsplash.com/photo-1519974719765-e6559eac2575?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=70',
  'https://images.unsplash.com/photo-1533759413974-9e15f3b745ac?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1934&q=70',
  'https://images.unsplash.com/photo-1501876725168-00c445821c9e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=70',
];
class HeroHeader extends Component {
  state = {
    urls: slideImages.map(image => null),
  };

  componentDidMount() {
    this.loadSlideImages();
  }

  loadSlideImages = () => {
    const { setSlidesAreLoaded } = this.props;

    slideImages.forEach((image, index) => {
      const totalImages = slideImages.length;
      const newImage = new Image();
      newImage.src = image;

      newImage.onload = () => {
        const urls = [...this.state.urls];
        urls[index] = image;
        this.setState({ urls });

        if (index === totalImages - 1) {
          setSlidesAreLoaded();
        }
      };
    });
  };

  render() {
    return (
      <div>
        <header className="main__hero">
          <div className="hero__info">
            <div className="hero__info-wrapper">
              <img
                src="/images/logoBlanc2.png"
                alt="White space"
                className="hero__info-logo"
                style={{width: "25%"}}
              />
              <div className="hero__info-content">
                <div className="hero__info-left">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/hnim.gnourt/"
                  >
                    <img
                      src="/images/hero-instagram-brands.svg"
                      alt="White space's instagram"
                      className="hero__info-social-icon"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/sipp.minhh/"
                  >
                    <img
                      src="/images/hero-facebook-square-brands.svg"
                      alt="White space's facebook"
                      className="hero__info-social-icon"
                    />
                  </a>
                </div>
                <div className="hero__info-right">
                  <div className="hero__info-item">
                    <img
                      src="/images/hero-phone-alt-solid.svg"
                      alt="tel"
                      className="hero__info-contact-icon"
                    />
                    <span className="hero__info-text">+33 (0)6 19 32 57 91</span>
                  </div>
                  <div className="hero__info-item">
                    <img
                      src="/images/hero-envelope-solid.svg"
                      alt="email"
                      className="hero__info-contact-icon"
                    />
                    <span className="hero__info-text">
                      contact@cab-conciergerie.com
                    </span>
                  </div>
                  <div className="hero__info-item">
                    <img
                      src="/images/hero-home-solid.svg"
                      alt="address"
                      className="hero__info-contact-icon"
                    />
                    <span className="hero__info-text">14 rue Chapsal, Joinville-le-pont</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero__slides">
            {this.state.urls.map((img, i) => (
              <div
                key={i}
                className="hero__slide"
                style={{ backgroundImage: `url(${img})` }}
              >
                <div className="hero__overlay"></div> {/* Element pour l'assombrissement */}
              </div>
            ))}
          </div>
        </header>
      </div>
    );
  }
}

export default HeroHeader;
