import React from 'react';

const RoomAmenities = props => {
  const { amenities } = props;
  if (!amenities) return null;

  const {
    wifi,
    breakfast,
    minibar,
    roomService,
    airConditioner,
    greatView,
    smokeFree,
    childFriendly,
    petFriendly,
    spa,
    sauna,
    tv,
  } = amenities;

  return (
    <div className="room-amenities">
      <ul className="room-amenities__list">
        <li className={`room-amenities__item ${wifi ? '' : 'non-available'}`}>
          <img
            src="/images/room-wifi.svg"
            alt="Wifi"
            className="room-amenities__icon"
          />
          <span className="room-amenities__title">Wi-Fi</span>
        </li>
        <li
          className={`room-amenities__item ${spa ? '' : 'non-available'}`}
        >
          <img
            src="/images/hot-tub-person.svg"
            alt="Spa"
            className="room-amenities__icon"
          />
          <span className="room-amenities__title">Spa</span>
        </li>
        <li
          className={`room-amenities__item ${sauna ? '' : 'non-available'}`}
        >
          <img
            src="/images/person-shelter.svg"
            alt="Sauna"
            className="room-amenities__icon"
          />
          <span className="room-amenities__title">Sauna</span>
        </li>
        <li
          className={`room-amenities__item ${tv ? '' : 'non-available'}`}
        >
          <img
            src="/images/tv.svg"
            alt="TV"
            className="room-amenities__icon"
          />
          <span className="room-amenities__title">Vidéo projecteur</span>
        </li>
        {/* <li
          className={`room-amenities__item ${breakfast ? '' : 'non-available'}`}
        >
          <img
            src="/images/room-breakfast.svg"
            alt="Breakfast"
            className="room-amenities__icon"
          />
          <span className="room-amenities__title">Petit-déjeuner</span>
        </li> */}
        <li
          className={`room-amenities__item ${minibar ? '' : 'non-available'}`}
        >
          <img
            src="/images/room-bar.svg"
            alt="Mini Bar"
            className="room-amenities__icon"
          />
          <span className="room-amenities__title">Mini Bar</span>
        </li>
        {/* <li
          className={`room-amenities__item ${
            roomService ? '' : 'non-available'
          }`}
        >
          <img
            src="/images/room_service.svg"
            alt="Room Service"
            className="room-amenities__icon"
          />
          <span className="room-amenities__title">Service en chambre</span>
        </li> */}
        {/* <li
          className={`room-amenities__item ${
            airConditioner ? '' : 'non-available'
          }`}
        >
          <img
            src="/images/room-breeze.svg"
            alt="Air-Conditioner"
            className="room-amenities__icon"
          />
          <span className="room-amenities__title">Climatisation</span>
        </li> */}
        <li
          className={`room-amenities__item ${greatView ? '' : 'non-available'}`}
        >
          <img
            src="/images/room-mountain-range.svg"
            alt="Great-View"
            className="room-amenities__icon"
          />
          <span className="room-amenities__title">Belle vue</span>
        </li>
        <li
          className={`room-amenities__item ${smokeFree ? '' : 'non-available'}`}
        >
          <img
            src="/images/room-no-smoke-symbol.svg"
            alt="Smoke-Free"
            className="room-amenities__icon"
          />
          <span className="room-amenities__title">Non-fumeur</span>
        </li>
        <li
          className={`room-amenities__item ${
            petFriendly ? '' : 'non-available'
          }`}
        >
          <img
            src="/images/room-dog.svg"
            alt="Pet Friendly"
            className="room-amenities__icon"
          />
          <span className="room-amenities__title">Animaux autorisés</span>
        </li>
        <li
          className={`room-amenities__item ${
            childFriendly ? '' : 'non-available'
          }`}
        >
          <img
            src="/images/room-crawling-baby-silhouette.svg"
            alt="Child Friendly"
            className="room-amenities__icon"
          />
          <span className="room-amenities__title">Enfants bienvenus</span>
        </li>
      </ul>
    </div>
  );
};

export default RoomAmenities;
