import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

export const firebaseConfig = {
  apiKey: "AIzaSyAnU3M4qbd9Wo-hQHhpgN9COtaTW7QIpXU",
  authDomain: "cheval-a-bascule.firebaseapp.com",
  databaseURL: "https://cheval-a-bascule-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "cheval-a-bascule",
  storageBucket: "cheval-a-bascule.appspot.com",
  messagingSenderId: "431427678585",
  appId: "1:431427678585:web:1797d3f63ab1f3402bbfa8"
};

// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const database = firebase.database();
