import React from 'react';
import LineBreak from '../LineBreak/index';

const RoomInfo = ({ data }) => {
  if (!data?.descriptionShort) return null;

  const {
    guestMin,
    guestMax,
    footage,
    bed,
    privateBath,
  } = data?.descriptionShort;
  const { checkInEarly, checkInLate, checkOut } = data?.checkInAndOut;
  const { checkInEarlyAfternoon, checkInLateAfternoon, checkOutAfternoon } = data?.checkInAndOutAfternoon;

  const guestCount = guestMin === guestMax ? guestMin : 0;

  return (
    <div className="room-info">
      <h2 className="room-info__title">{data?.name}</h2>
      <ul className="room-info__intro-list">
        <li className="room-info__intro-item">
          Nombre d'invités : {guestCount ? guestCount : `${guestMin} - ${guestMax}`}
        </li>
        <li className="room-info__intro-item">Superficie : {footage} m²</li>
        <li className="room-info__intro-item">
          Lits : {bed?.map(b => b + ', ')}
        </li>
        <li className="room-info__intro-item">
          Salle de bain privée : {privateBath ? 'Oui' : 'Non'}
        </li>
      </ul>
      <p className="room-info__description">{data?.description}</p>
      <p className="room-info__description">{data?.description2}</p>
      <p className="room-info__description">{data?.description3}</p>
      <LineBreak location="room-info" />
      <span className="room-info__check-title">Réservations pour l'après-midi</span>
      <div className="room-info__checks">
        <div className="room-info__check">
          <span className="room-info__check-title">Arrivée</span>
          <span className="room-info__check-time">{`${checkInEarlyAfternoon} - ${checkInLateAfternoon}`}</span>
        </div>
        <div className="room-info__check">
          <span className="room-info__check-title">Départ</span>
          <span className="room-info__check-time">{checkOutAfternoon}</span>
        </div>
      </div>
      <div style={{backgroundColor: "#E7E7E7", height: 1, width:"100%", marginTop: 20}}/>
      
        <span className="room-info__check-title">Réservations pour le soir</span>
        <div className="room-info__checks">
          <div className="room-info__check">
            <span className="room-info__check-title">Arrivée</span>
            <span className="room-info__check-time">{`${checkInEarly} - ${checkInLate}`}</span>
          </div>
          <div className="room-info__check">
            <span className="room-info__check-title">Départ</span>
            <span className="room-info__check-time">{checkOut}</span>
          </div>
        </div>
      
    </div>
  );
};

export default RoomInfo;
