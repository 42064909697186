import { Button, Input, NativeSelect, TextField } from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';
import React from 'react';
import { format, set } from 'date-fns';

const ManageRoomInfo = ({
  descriptionShort,
  setDescriptionShort,
  checkInAndOut,
  setCheckInAndOut,
  checkInAndOutAfternoon,
  setCheckInAndOutAfternoon,
  name,
  description,
  description2,
  description3,
  setFirstLevelValue,
  setBed,
}) => {
  const { guestMin, guestMax, footage, bed, privateBath } = descriptionShort ?? {};
  const { checkInEarly, checkInLate, checkOut } = checkInAndOut ?? [];
  const { checkInEarlyAfternoon, checkInLateAfternoon, checkOutAfternoon } = checkInAndOutAfternoon ?? [];

  const checkInEarlyHours = checkInEarly ? checkInEarly.split(':')[0] : '';
  const checkInEarlyMinutes = checkInEarly ? checkInEarly.split(':')[1] : '';

  const checkInLateHours = checkInLate ? checkInLate.split(':')[0] : '';
  const checkInLateMinutes = checkInLate ? checkInLate.split(':')[1] : '';

  const checkOutHours = checkOut ? checkOut.split(':')[0] : '';
  const checkOutMinutes = checkOut ? checkOut.split(':')[1] : '';


  const checkInEarlyHoursAfternoon = checkInEarlyAfternoon ? checkInEarlyAfternoon.split(':')[0] : '';
  const checkInEarlyMinutesAfternoon = checkInEarlyAfternoon ? checkInEarlyAfternoon.split(':')[1] : '';

  const checkInLateHoursAfternoon = checkInLateAfternoon ? checkInLateAfternoon.split(':')[0] : '';
  const checkInLateMinutesAfternoon = checkInLateAfternoon ? checkInLateAfternoon.split(':')[1] : '';

  const checkOutHoursAfternoon = checkOutAfternoon ? checkOutAfternoon.split(':')[0] : '';
  const checkOutMinutesAfternoon = checkOutAfternoon ? checkOutAfternoon.split(':')[1] : '';

  const addBed = () => {
    setBed([...bed, '']);
  };

  const handleRemoveBed = index => {
    bed.splice(index, 1);
    setBed(bed);
  };

  const onChangeBedValue = (e, index) => {
    bed[index] = e.target.value;
    setBed(bed);
  };

  function onChangeCheckInEarly(value) {
    setCheckInAndOut('checkInEarly', format(value, 'HH:mm'));
  }

  function onChangeCheckInLate(value) {
    setCheckInAndOut('checkInLate', format(value, 'HH:mm'));
  }

  function onChangeCheckOut(value) {
    setCheckInAndOut('checkOut', format(value, 'HH:mm'));
  }

  function onChangeCheckInEarlyAfternoon(value) {
    setCheckInAndOutAfternoon('checkInEarlyAfternoon', format(value, 'HH:mm'));
  }

  function onChangeCheckInLateAfternoon(value) {
    setCheckInAndOutAfternoon('checkInLateAfternoon', format(value, 'HH:mm'));
  }

  function onChangeCheckOutAfternoon(value) {
    setCheckInAndOutAfternoon('checkOutAfternoon', format(value, 'HH:mm'));
  }

  return (
    <>
      <div className="room-info">
        <span className="room-info__check-title">Nom de la Chambre</span>
        <TextField
          id="outlined-margin-dense"
          margin="dense"
          variant="outlined"
          fullWidth
          placeholder="Nom de la Chambre"
          value={name}
          name='name'
          onChange={setFirstLevelValue}
        />
        <ul className="room-info__intro-list">
          <li className="room-info__intro-item">
            <Input
              placeholder="Nombre d'Invités Minimum"
              inputProps={{ 'aria-label': 'description' }}
              type="number"
              name="guestMin"
              value={guestMin}
              onChange={setDescriptionShort}
            />{' '}
            -{' '}
            <Input
              placeholder="Nombre d'Invités Maximum"
              inputProps={{ 'aria-label': 'description' }}
              type="number"
              name="guestMax"
              value={guestMax}
              onChange={setDescriptionShort}
            />
          </li>
          <li className="room-info__intro-item">
            <Input
              placeholder="Superficie"
              inputProps={{ 'aria-label': 'description' }}
              type="number"
              name="footage"
              value={footage}
              onChange={setDescriptionShort}
            />
            m²
          </li>
          <li style={{ margin: '15px 0px' }} className="room-info__intro-item">
            Lits : <Button onClick={addBed}>Ajouter un Lit</Button>
            {bed?.map((b, index) => (
              <div style={{ margin: 10 }}>
                <NativeSelect
                  key={index}
                  value={b}
                  onChange={e => onChangeBedValue(e, index)}
                  inputProps={{ 'aria-label': 'bed' }}
                >
                  <option selected>Lit</option>
                  <option value="Petit Lit Simple">Petit Lit Simple</option>
                  <option value="Grand Lit Simple">Grand Lit Simple</option>
                  <option value="Petit Lit Double">Petit Lit Double</option>
                  <option value="Grand Lit Double">Grand Lit Double</option>
                </NativeSelect>
                <Button onClick={() => handleRemoveBed(index)}>Supprimer</Button>
              </div>
            ))}{' '}
          </li>
          <li className="room-info__intro-item">
            <Input
              placeholder="Salle de Bain Privée"
              inputProps={{ 'aria-label': 'description' }}
              type="number"
              name="privateBath"
              value={privateBath}
              onChange={setDescriptionShort}
            />
          </li>
        </ul>
        <TextField
          id="outlined-multiline-static"
          label="Description"
          placeholder="Description sommaire de la chambre"
          multiline
          fullWidth
          rows={4}
          variant="outlined"
          name="description"
          value={description}
          onChange={setFirstLevelValue}
        />
        <TextField
          id="outlined-multiline-static"
          label="Description"
          placeholder="Description sommaire de la chambre"
          multiline
          fullWidth
          rows={4}
          variant="outlined"
          name="description2"
          value={description2}
          onChange={setFirstLevelValue}
        />
        <TextField
          id="outlined-multiline-static"
          label="Description"
          placeholder="Description sommaire de la chambre"
          multiline
          fullWidth
          rows={4}
          variant="outlined"
          name="description3"
          value={description3}
          onChange={setFirstLevelValue}
        />
        <div className="room-info__checks">
        <div className="room-info__check">
            <span className="room-info__check-title">Arrivée Soir</span>
            <span className="room-info__check-time">
              <TimePicker
                ampm={false}
                margin="normal"
                label="Arrivée tôt"
                value={set(new Date(), {
                  hours: checkInEarlyHours,
                  minutes: checkInEarlyMinutes,
                })}
                onChange={onChangeCheckInEarly}
              />
              <TimePicker
                ampm={false}
                margin="normal"
                label="Arrivée tardive"
                value={set(new Date(), {
                  hours: checkInLateHours,
                  minutes: checkInLateMinutes,
                })}
                onChange={onChangeCheckInLate}
              />
            </span>
          </div>
          <div className="room-info__check">
            <span className="room-info__check-title">Départ Soir</span>
            <span className="room-info__check-time">
              <TimePicker
                ampm={false}
                margin="normal"
                label="Départ"
                value={set(new Date(), {
                  hours: checkOutHours,
                  minutes: checkOutMinutes,
                })}
                onChange={onChangeCheckOut}
              />
            </span>
          </div>
          <div className="room-info__check">
            <span className="room-info__check-title">Arrivée AM</span>
            <span className="room-info__check-time">
              <TimePicker
                ampm={false}
                margin="normal"
                label="Arrivée tôt"
                value={set(new Date(), {
                  hours: checkInEarlyHoursAfternoon,
                  minutes: checkInEarlyMinutesAfternoon,
                })}
                onChange={onChangeCheckInEarlyAfternoon}
              />
              <TimePicker
                ampm={false}
                margin="normal"
                label="Arrivée tardive"
                value={set(new Date(), {
                  hours: checkInLateHoursAfternoon,
                  minutes: checkInLateMinutesAfternoon,
                })}
                onChange={onChangeCheckInLateAfternoon}
              />
            </span>
          </div>
          <div className="room-info__check">
            <span className="room-info__check-title">Départ AM</span>
            <span className="room-info__check-time">
              <TimePicker
                ampm={false}
                margin="normal"
                label="Départ"
                value={set(new Date(), {
                  hours: checkOutHoursAfternoon,
                  minutes: checkOutMinutesAfternoon,
                })}
                onChange={onChangeCheckOutAfternoon}
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageRoomInfo;
