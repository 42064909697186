import React, { useEffect, useState } from "react";
import RoomPrice from './RoomPrice';
import BookingForm from './BookingForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// const stripeApiKey = process.env.REACT_APP_STRIPE_API_KEY;
// const stripeSecretApiKey = process.env.REACT_APP_OTHER_KEY;

const BookingCard = props => {

  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    fetch("https://cab-conciergerie.com:5252/config").then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
    console.log(stripePromise)
  }, []);

  useEffect(() => {
    fetch("https://cab-conciergerie.com:5252/create-payment-intent", {
      method: "POST",
      body: JSON.stringify({}),
    }).then(async (result) => {
      var { clientSecret } = await result.json();
      setClientSecret(clientSecret);
    });
  }, []);


  const {
    roomIsLoading,
    normalDayPrice,
    holidayPrice,
    normalDayPriceAfternoon,
    holidayPriceAfternoon,
    roomID,
    bookingData,
    setBookingData,
    clearBookingData,
    setTotalPriceInBooking,
  } = props;
  return (
    <div className="booking-card">
      <div className="booking-card__wrapper">
        <RoomPrice
          roomIsLoading={roomIsLoading}
          normalDayPrice={normalDayPrice}
          holidayPrice={holidayPrice}
          normalDayPriceAfternoon={normalDayPriceAfternoon}
          holidayPriceAfternoon={holidayPriceAfternoon}
        />
         {clientSecret && stripePromise && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <BookingForm
              {...{
                normalDayPrice,
                holidayPrice,
                normalDayPriceAfternoon,
                holidayPriceAfternoon,
                roomID,
                bookingData,
                setBookingData,
                clearBookingData,
                setTotalPriceInBooking,
              }}
            />
          </Elements>
         )}
      </div>
    </div>
  );
};

export default BookingCard;
