import { toast } from 'react-toastify';
import { isBefore, isAfter } from 'date-fns';

export function numberWithCommas(x) {
  if (x == null || x === undefined) return '';
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function isToday(inputDate) {
  const today = new Date();
  const date = new Date(inputDate);
  if (today.setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0)) return true;
  else return false;
}

export function isBetween(startDate, date, endDate) {
  return Boolean(
    isBefore(new Date(startDate), new Date(date)) &&
      isAfter(new Date(endDate), new Date(date)),
  );
}

export function getReserveStartAndEnd(start, end) {
  const timeStart = new Date(start);
  const timeEnd = new Date(end);

  // Tableau des noms des mois en français
  const months = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre'
  ];

  let res;

  if (timeStart.getMonth() === timeEnd.getMonth()) {
    res = `${timeStart.getDate()}-${timeEnd.getDate()} ${months[timeStart.getMonth()]}`;
  } else if (timeStart.getMonth() !== timeEnd.getMonth()) {
    res = `${timeStart.getDate()} ${months[timeStart.getMonth()]}-${timeEnd.getDate()} ${months[timeEnd.getMonth()]}`;
  }
  return res;
}


//#region notification

// success notification
export const defaultSuccessCB = msg =>
  toast.success(msg ? `✌ Thành Công: ${msg}!` : '✌ Thành Công!');

// fail notification
export const defaultFailCB = err =>
  toast.error(err ? `🚫 Lỗi: ${err}!` : '🚫 Lỗi!');

// warn notification
export const defaultWarnCB = msg =>
  toast.warn(msg ? `❗ ${msg}` : '❗ Cảnh báo.');

export const loadingToast = () =>
  toast.dark('🦄 Loading...', {
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    progress: undefined,
  });

//#endregion

export const statusCode = {
  reserveConfirmation: 'reserveConfirmation', // chờ xác nhận
  reserveCancel: 'reserveCancel', // hủy đặt phòng
  reserveAccept: 'reserveAccept', // xác nhận đặt phòng
  checkingIn: 'checkingIn', // chờ check in
  notCheckIn: 'notCheckIn', // không check in
  checkedIn: 'checkedIn', // không check in
  checkOut: 'checkOut', // check out
  paid: 'paid', //đã trả tiền
};

export const statusString = {
  reserveConfirmation: 'En attente de confirmation',
  reserveCancel: 'Réservation annulée',
  reserveAccept: 'Réservation confirmée',
  checkingIn: 'En attente du Check-In',
  notCheckIn: 'Pas d\'enregistrement',
  checkedIn: 'En cours',
  checkOut: 'Check-out effectué',
  paid: 'Payé',
};

