import React from "react";

import { Ellipsis } from "react-spinners-css";
import { numberWithCommas } from "../../utils";

const RoomPrice = ({ roomIsLoading, normalDayPrice, holidayPrice, normalDayPriceAfternoon, holidayPriceAfternoon }) => {
  return (
    <>
    <div className="booking-card__prices">
      <div className="booking-card__price-item">
        <span className="booking-card__price-title">Du lundi au jeudi chaque nuit</span>
        <span className="booking-card__price-per-night">
          {roomIsLoading ? (
            <Ellipsis color="#111" />
          ) : (
            `${numberWithCommas(normalDayPrice)} EUR`
          )}
        </span>
      </div>
      <div className="booking-card__price-item">
        <span className="booking-card__price-title">Du vendredi au dimanche chaque nuit</span>
        <span className="booking-card__price-per-night">
          {roomIsLoading ? (
            <Ellipsis color="#111" />
          ) : (
            `${numberWithCommas(holidayPrice)} EUR`
          )}
        </span>
      </div>
    </div>
    <div className="booking-card__prices">
      <div className="booking-card__price-item">
        <span className="booking-card__price-title">Du lundi au jeudi l'après-midi</span>
        <span className="booking-card__price-per-night">
          {roomIsLoading ? (
            <Ellipsis color="#111" />
          ) : (
            `${numberWithCommas(normalDayPriceAfternoon)} EUR`
          )}
        </span>
      </div>
      <div className="booking-card__price-item">
        <span className="booking-card__price-title">Du vendredi au dimanche l'après-midi</span>
        <span className="booking-card__price-per-night">
          {roomIsLoading ? (
            <Ellipsis color="#111" />
          ) : (
            `${numberWithCommas(holidayPriceAfternoon)} EUR`
          )}
        </span>
      </div>
    </div>
    </>
  );
};

export default RoomPrice;
