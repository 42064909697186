import { Input } from '@material-ui/core';
import React from 'react';

const ManagePrice = props => {
  const { normalDayPrice, holidayPrice, normalDayPriceAfternoon, holidayPriceAfternoon, setFirstLevelValue } = props;
  return (
    <>
      <div>
        Prix en Semaine Soir :{' '}
        <Input
          placeholder="Prix"
          inputProps={{ 'aria-label': 'description' }}
          type="number"
          name="normalDayPrice"
          value={normalDayPrice}
          onChange={setFirstLevelValue}
        />{' '}
        EUR
      </div>
      <div>
        Prix en Week-end et jours fériées Soir:{' '}
        <Input
          placeholder="Prix"
          inputProps={{ 'aria-label': 'description' }}
          type="number"
          name="holidayPrice"
          value={holidayPrice}
          onChange={setFirstLevelValue}
        />{' '}
        EUR
      </div>
      <div>
      Prix en Semaine AM :{' '}
        <Input
          placeholder="Prix"
          inputProps={{ 'aria-label': 'description' }}
          type="number"
          name="normalDayPriceAfternoon"
          value={normalDayPriceAfternoon}
          onChange={setFirstLevelValue}
        />{' '}
        EUR
      </div>
      <div>
        Prix en Week-end et jours fériées AM :{' '}
        <Input
          placeholder="Prix"
          inputProps={{ 'aria-label': 'description' }}
          type="number"
          name="holidayPriceAfternoon"
          value={holidayPriceAfternoon}
          onChange={setFirstLevelValue}
        />{' '}
        EUR
      </div>
    </>
  );
};

export default ManagePrice;